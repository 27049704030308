import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Alert} from "antd";
import logo from "../../assets/images/sp/sp-logo-1.svg"
import cifre1 from "../../assets/images/sp/1/cifre-1.jpg"
import cifre2 from "../../assets/images/sp/1/cifre-2.jpg"
import sp from "../../assets/images/sp/1/sp.jpg"
import cda from "../../assets/images/sp/1/cda.jpg"
import direzione from "../../assets/images/sp/1/direzione.jpg"
import {LinkOutlined} from "@ant-design/icons";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`BancaStato | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 1</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://www.bancastato.ch" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <h2 className="text-primary">La storia di BancaStato</h2>
                        <p className="text-justify">BancaStato, nata nel 1915, è un istituto bancario universale
                            con oltre un secolo di vita. Nel
                            1916 la Banca si aggregò all’Unione delle Banche Cantonali Svizzere e con il passare
                            dei
                            decenni l’Istituto guadagnò sempre di più la fiducia dei ticinesi. Nel 1961 alla
                            Banca fu
                            concesso di effettuare operazioni non solo prettamente bancarie ma anche
                            commerciali. Nel
                            2003 avvenne la trasformazione da Banca commerciale e ipotecaria a Banca di natura
                            universale. Nel 2010, con l’acquisizione di una partecipazione di controllo in
                            UniCredit
                            (Suisse) Bank SA, poi ribattezzata in “Axion SWISS Bank SA”, nacque il “Gruppo
                            BancaStato”.
                            Nel 2013 BancaStato acquisì la totalità del suo controllo.</p>
                        <h2 className="text-primary">I valori portanti di BancaStato</h2>
                        <p className="text-justify">
                            <ul>
                                <li>
                                    Conoscenza del cliente
                                </li>
                                <li>
                                    Competenza
                                </li>
                                <li>
                                    Qualità di servizio
                                </li>
                                <li>
                                    Velocità decisionale
                                </li>
                            </ul>
                        </p>
                        <p className="text-justify">
                            BancaStato appartiene alla Repubblica e Cantone Ticino e agisce su <strong>mandato
                            pubblico</strong> con il preciso scopo di favorire lo sviluppo economico del
                            territorio e fornire ai
                            ticinesi la possibilità di investire i loro risparmi in maniera sicura e redditizia.
                            Lo Stato risponde di tutti gli impegni della Banca non coperti da mezzi propri.
                            BancaStato è una banca speciale: si differenzia dalla concorrenza non solo per la
                            qualità dei suoi prodotti e servizi, per la grande conoscenza del territorio e la
                            spiccata prossimità con i ticinesi, ma anche per l’offerta di <strong>garanzia dello
                            Stato</strong>.
                        </p>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <Row align="top">
                            <Col xs={24}>
                                <h2 className="text-primary">Il Gruppo BancaStato in cifre</h2>
                                <img src={cifre1} alt="BancaStato" className="sp-img d-inline-block align-top pe-lg-5"/>
                                <img src={cifre2} alt="BancaStato" className="sp-img d-inline-block align-top"/>
                                <Divider/>
                                <h2 className="text-primary">Non solo “quanto”: ma anche “come”</h2>
                                <p>BancaStato - oltre a offrire prodotti e servizi convenienti e trasparenti,
                                    posizionarsi come un datore di lavoro attento e riversare buona parte degli utili
                                    netti nelle casse statali - opera con un occhio di riguardo nei confronti
                                    dell’ambiente e persegue una politica di sostegno alle realtà associative
                                    locali.</p>
                                <p>Proprio in merito all’ultimo punto, BancaStato supporta centinaia di società,
                                    associazioni e realtà attive in Ticino a livello sportivo, culturale e benefico: il
                                    lavoro che svolgono in maniera capillare è per il territorio un grande valore
                                    aggiunto, e siamo fieri di poter fornire loro un aiuto concreto e tangibile. In
                                    questo contesto, siamo particolarmente attenti a supportare i settori giovanili di
                                    molte squadre, che consentono di preservare e tramandare alle future generazioni
                                    sani valori che condividiamo appieno.</p>
                                <h4>Per tutti questi motivi il nostro “slogan” è: <span
                                    className="text-primary fw-bold">“noi per voi”.</span></h4>
                                <img src={sp} alt="BancaStato" className="sp-img"/>
                                <Divider/>
                                <h2 className="text-primary">Direzione generale</h2>
                                <p>
                                    <ul>
                                        <li>Fabrizio Cieslakiewicz, Presidente della Direzione generale, Responsabile ad interim dell'Area Private Banking e GPE</li>
                                        <li>Daniele Albisetti, Responsabile dell'Area Finanza</li>
                                        <li>Curzio De Gottardi, Responsabile dell'Area Prodotti e Servizi</li>
                                        <li>Patrick Lafranchi, Responsabile dell'Area Rischi</li>
                                        <li>Gabriele Zanzi, Responsabile dell'Area Retail e Aziendale</li>
                                    </ul>
                                </p>
                                <Divider/>
                                <h2 className="text-primary">Consiglio di Amministrazione</h2>
                                <p>
                                    <ul>
                                        <li>Bernardino Bulla, Presidente del Consiglio di amministrazione</li>
                                        <li>Raoul Paglia, Vicepresidente del Consiglio di amministrazione</li>
                                        <li>Michela Ferrari-Testa, membro del Consiglio di amministrazione</li>
                                        <li>Giovanni Jelmini, membro del Consiglio di amministrazione</li>
                                        <li>Daniel Joss, membro del Consiglio di amministrazione</li>
                                        <li>Stefano Santinelli, membro del Consiglio di amministrazione</li>
                                        <li>Luca Soncini, membro del Consiglio di amministrazione</li>
                                    </ul>
                                </p>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
